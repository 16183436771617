.cards {
    [class*="col-md-"],
    [class*="col-sm-"] {
        height: 100%;
        width: 100%;
    }
    .card {
        background-color: rgba(55, 171, 248, 0.7);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 1.5rem;
        border: 0;
        &:hover {
            text-decoration: none;
        }
        .inner {
            padding: 12rem 3rem 3rem 3rem;
            > * {
                z-index: 2;
                position: relative;
                //color: $white;
            }
            h2{
                color: $white;
            }
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                height: 100%;
                width: 100%;
                background-color: rgba(55, 171, 248, 0.7);
                z-index: 1;
            }
        }
        h2 {
            font-weight: 500;
            font-size: 3.5rem;
        }
        .sbtn{
            margin-top: 1.5rem;
        }
        .egyeni {
            background-image: url("../../../images/egyeni_lofasz.png");
        }

        .csoportos {
            background-image: url("../../../images/csoportos.png");
        }

        .reumatologiai {
            background-image: url("../../../images/reuma.png");
        }
    }
}
