@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap&subset=latin-ext");

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;900&family=Open+Sans:wght@300;900&display=swap&subset=latin-ext");

html,
body {
    font-family: "Open sans", sans-serif;
    font-size: 10px;
    line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 900;
    a {
        font-weight: 900;
    }
}

h2 {
    font-size: 4rem;
}

h3 {
    font-size: 3.6rem;
}

h4 {
    font-size: 2.2rem;
}

span,
p {
    &.center {
        text-align: center;
    }
}

label, label.col-form-label {
    font-size: 14px;
    font-weight: 600;
}

.card-header {
    font-weight: 600;
}
