.subpage-top-position {
    position: relative;
    top: 80px;
    padding-bottom: 153px;
    @media (min-width: $md) {
        background-size: 75%;
    }
}

.overf-x-scroll {
    @media (max-width: $md) {
        overflow-x: scroll;
    }
}

.sub-page-background {
    background: url("../../../images/one_stripe_opac10.png");
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 95%;
    @media (min-width: $md) {
        background-size: 95%;
        background-position: top left;
    }
    @media (min-width: $lg) {
        background-size: 60%;
    }

    @media (min-width: $xl) {
        background-size: 43%;
    }

    //@media (min-width: 1600px) {
    //    background-size: 43%;
    //}
}

.sub-page-title {
    font-family: "Open sans", sans-serif;
    font-weight: 700;
    padding-top: 50px;
    padding-bottom: 50px;
    text-transform: uppercase;
}

#prices, #profile {
    .w-fit-cont {
        width: fit-content;
    }

    li {
        color: $szimblue;
    }

    .table-container {
        border: $sbtn-border dashed $szimgrey;
        padding: 20px 10px;
        margin-bottom: 20px;

        @media (max-width: $md) {
            border: none;
            padding: 0;
            table {
                max-width: 100%;
                th,
                td {
                    padding-right: 0;
                    padding-left: 0;
                }
                th:not(:last-of-type) {
                    border-right: 1px solid $gray-300;
                }
            }
        }

        table {
            color: $szimgrey;
        }
    }

    .p-t-0 {
        padding-top: 0 !important;
    }

    .table-title {
        padding: 25px 15px 5px;
    }

    @media screen and (max-width: 768px) {
        table thead {
            display: none;
        }
        table td {
            display: flex;
            min-width: 170px;
        }

        table td::before {
            content: attr(label);
            font-weight: bold;
            width: 120px;
            min-width: 120px;
        }
        td.hide-mob {
            display: none;
        }
    }
}

#timetable {
    li {
        color: $szimblue;
    }

    .table-container {
        padding: 0 10px;
        margin-bottom: 20px;
        table {
            color: $szimgrey;

            th {
                border-bottom: 1px solid $szimgrey;
                border-top: none;
            }

            td {
                border: 1px solid $szimgrey;
            }
        }
    }

    .table-title {
        padding: 25px 15px 5px;
    }
}

.szim-subpage-card-container {
    .full-cover-img {
        height: 450px;
        background-size: cover;
        //background:url("../images/hirek.jpg") no-repeat center center fixed;
        background-repeat: no-repeat;
        background-position: 0px 25px;
        background-attachment: fixed;
    }

    .container-elevation {
        position: relative;
        top: -200px;
        background-color: white;
        border-radius: 5px;
    }

    .subpage-card-container-title {
        font-family: "Open sans", sans-serif;
        font-weight: 700;
        padding-top: 50px;
        padding-bottom: 50px;
        text-transform: uppercase;
    }
}

.full-cover-img {
    height: 450px;
    background-size: cover;
    //background:url("../images/hirek.jpg") no-repeat center center fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.container-elevation {
    position: relative;
    top: -200px;
    background-color: white;
}

.subpage-card-container-title {
    font-family: "Open sans", sans-serif;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
}

#contacts {
    .center {
        margin: 2rem 0 0 0;
    }
    table {
        margin: 2rem 0;
        max-width: 100%;
    }
}

// price tables

#prices {
    table {
        td,
        th {
            font-size: 1.2rem;
        }
    }
    .c-szim-blue {
        font-weight: 800;
    }
}
