p,
span,
a,
ul,
li {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 300;
}

#main {
    position: relative;
    min-height: 100vh;
}

.page-container {
    position: relative;
    min-height: 100vh;
    top: 5rem;
    @media (max-width: $xl) {
        top: 0;
    }
}

.sub-page-container {
    padding-top: 140px;
    padding-bottom: 140px;
    min-height: 600px;
}

// Color classes
.c-szim-blue {
    color: $szimblue;
}

.c-szim-mid-blue {
    color: $szim-midblue;
}

.c-szim-grey {
    color: $szimgrey;
}

.c-szim-light-grey {
    color: $szim-lightgrey;
}

.c-szim-light-blue {
    color: $szim-lightblue;
}

.c-white {
    color: white;
}

// Font classes

.italic {
    font-style: italic;
}

.font-os {
    font-family: "Open Sans", sans-serif;
}

.font-os-medium {
    font-family: "Open Sans", sans-serif;
}

.font-os-heavy {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

.font-os-thin {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}

.font-os-light {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}

.font-os-regular {
    font-family: "Open Sans", sans-serif;
}

.fs-8 {
    font-size: 0.8em;
    line-height: 1.2em;
}

.fs-12 {
    font-size: 1.2em;
    line-height: 1.2em;
}

.fs-16 {
    font-size: 16px;
    line-height: 18px;
}

.fs-18 {
    font-size: 18px;
    line-height: 20px;
}

.fs-22 {
    font-size: 22px;
    line-height: 24px;
}

.fs-24 {
    font-size: 24px;
    line-height: 1.5;
}

.fs-30 {
    font-size: 3em;
    line-height: 1.2em;
}

.fs-36 {
    font-size: 3.6em;
    line-height: 1.2em;
}

.lh-45 {
    line-height: 45px;
}

.bold-text {
    font-weight: bold;
}

.fw-700 {
    font-weight: bold;
}

.w-900 {
    font-weight: 900;
}

.fw-600 {
    font-weight: 600;
}

// Positioning

.p-relative {
    position: relative;
}

.z-10 {
    z-index: 10;
}

.z-100 {
    z-index: 100;
}

// Paddings

.no-padding {
    padding: 0;
}

.p-15 {
    padding: 15px;
}

.p-50 {
    padding: 50px;
}

.p-tb-5-lr-10 {
    padding: 5px 10px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-30 {
    padding: 30px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-60 {
    padding-bottom: 60px;
}

.p-t-60 {
    padding-top: 60px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-0-b-30-lr-15 {
    padding: 0 15px 30px 15px;
}

// Margins

.no-margins {
    margin: 0;
}

.m-8 {
    margin: 8px;
}

.m-b-15 {
    margin-bottom: 15px;
}

// Box-Shadow

.box-shadow {
    -moz-box-shadow: 0px 0px 5px 0px #c2c2c2;
    -webkit-box-shadow: 0px 0px 5px 0px #c2c2c2;
    box-shadow: 0px 0px 5px 0px #c2c2c2;
}

.szim-dynamic-banner-logo-sm-pic {
    position: absolute;
    width: 40px;
    top: 20px;
    left: 20px;
    z-index: 101;
}

article .with-bg,
.subpage-top-position {
    &.subpage-top-position {
        padding-top: 45px;
        margin-bottom: 45px;
    }
    .container {
        border-radius: 5px;
        background: $white-fifty;
        box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    }
    #timetable {
        .container {
            background: $white;
        }
    }
    .container.no-bg {
        background: none;
        box-shadow: none;
    }
}

// timetable tweak

.rbc-events-container {
    > div {
        box-shadow: 0 0 1px 1px rgb(68 68 68);
    }
}
