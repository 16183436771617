@import "../szimmetria/resources/sass/variables";
@import "../szimmetria/resources/sass/mixins";

$footer-height: 350px;

footer {
    width: 100%;
    bottom: 0;
    background: $szimblue;
    padding: 30px;
    @media (max-width: $md) {
        position: inherit;
        bottom: initial;
        padding: 30px 15px 15px 15px;
    }

    @media (max-width: $sm) {
        padding: 30px 0 15px 0;
    }

    .footer-social-links {
        @media (max-width: $sm) {
            text-align: center;
        }
    }

    .footer-copyrights {
        @media (max-width: $sm) {
            text-align: center;
            padding-top: 30px;
        }
    }

    .facebook-plugin-container {
        @media (max-width: $md) {
            padding-top: 30px;
        }
    }

    .footer-logo {
        width: 100%;
        height: auto;
        max-height: 268px;
        @media (max-width: $md) {
            max-height: available;
        }
    }

    .footer-link-container {
        padding: 24px;
        @media (max-width: $sm) {
            padding: 0 24px;
        }

        .footer-link {
            padding: 12px 15px;
            line-height: 12px;
            font-size: 12px;

            a {
                &:hover {
                    color: #a4f1f6;
                }
            }
        }
    }

    .bottom-row {
        padding-top: 30px;

        .footer-icon {
            font-size: 30px;
            line-height: 27px;
            padding: 0 15px;
        }
    }

    iframe,
    .facebook-plugin-container,
    .fb_iframe_widget {
        max-width: 100%;
        @media (max-width: $sm) {
            span {
                max-width: 100%;
            }
            margin: 0 auto;
            display: block;
        }
    }

    .facebook-plugin-container {
        margin-bottom: 15px;
    }
}
