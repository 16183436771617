#subpage-top-nav {
    background-color: $szimblue;
    position: fixed;
    z-index: 110;
    &.fixed{
        top: 50px;
        @media (max-width: $xl) {
            display: none;
        }
    }
    .szim-dynamic-banner-top-row {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 50px;
        @media (max-width: $lg) {height: 80px }


        .col {
            white-space: nowrap;
            width: auto;
        }

        .szim-dynamic-banner-link-container {
            padding: 0 0 0 25px;

            a {
                margin: 0 25px 0 0;
                color: white;
            }

            .szim-dynamic-banner-link-dot {
                text-decoration: none;
                position: relative;

                &:hover:before {
                    content: '\2022';
                    height: 2px;
                    line-height: 20px;
                    font-size: 18px;
                    position: absolute;
                    top: 9px;
                    left: 50%;
                    z-index: 102;
                    transform: translate(-50%, 0px);
                    animation: fadeInFromNone 0.5s ease-out;

                    //    @include animation-width-mixin('grow', 0.5s, 1);
                }
            }
        }
    }

    .szim-dynamic-banner-carousel {
        padding-top: 70px;

        .carousel-text-container {
            .szim-dynamic-banner-text{
                display: inline-block;
                padding: 20px 0;
            }
            .szim-dynamic-banner-line{
                display: inline-block;
                background-color: white;
                height: 2px;
                width: 50px
            }
        }
    }

    .szim-dynamic-banner-logo-pic-container {
        max-width: 40px;
        margin-left: 15px;

        .szim-dynamic-banner-logo-pic {
            width: 40px;
        }
    }

    .szim-dynamic-banner-bg {
        .szim-dynamic-banner-background-pic {
            height: 379px;
        }

        .szim-dynamic-banner-front-pic {
            z-index: 100;
            position: absolute;
            bottom: 0;
            left: -74px;
        }
    }
}
