// Body
$body-bg: white;

// Typography
$font-family-sans-serif: "Open sans", sans-serif;
$font-family-medium-sans-serif: Avenir-Medium, sans-serif;
$font-family-heavy-sans-serif: Lato-Black, sans-serif;
$font-os-thin-sans-serif: Lato-Thin, sans-serif;
$font-os-light-sans-serif: Lato-Light, sans-serif;
$font-os-regular-sans-serif: Lato-Regular, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #000000;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$szimblue: #2e60a7;
$szimblue-transparent: rgba(46, 96, 167, 0.86);
$szim-lightblue-transparent: rgba(44, 141, 255, 0.4);
$szim-lightblue: #50d5fe;
$szim-midblue: #2c8dff;
$szimgrey: #4a4d4e;
$simgrey-border: #e6e6e6;
$szimwhite: #fff;
$szim-lightgrey: rgba(74, 77, 78, 0.2);
$white-fifty: rgba(255, 255, 255, 0.5);

$smooth-superfast: all 0.05s ease;
$smooth: all 0.25s ease;
$smooth-longer: all 0.5s ease 0s;

// Buttons
$sbtn-rad: 3px;
$sbtn-border: 1px;

// bootstrap widths

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin left-swim {
    animation-name: left-swim;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    @keyframes left-swim {
        0% {
            left: 15px;
        }
        50% {
            left: 0;
        }
        100% {
            left: 15px;
        }
    }
}

// fucxk
.content-card-text {
    padding: 0 15px 15px 15px;
    color: $szimgrey;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 300;
}

b,
strong {
    font-weight: bold;
}
