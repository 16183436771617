/* @include transition(all,2s,ease-out); */
@mixin my-transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}

@mixin center-h ($height) {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    /* More style: */
    height: $height;
}

@mixin center-v ($height) {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    /* More style: */
    height: $height;
}

@mixin animation-transform-mixin($name, $from, $to) {
    @keyframes #{$name} {
        0% {transform: translate3d($from, 0, 0); opacity: 0;}
        100% {transform: translate3d($to, 0, 0); opacity: 1;}
    }
}

@mixin animation-width-mixin($name, $interval, $iteration ) {
    animation-name: $name;
    animation-duration: $interval;
    animation-iteration-count: $iteration;
    -webkit-animation-name: $name;
    -webkit-animation-duration: $interval;
    -webkit-animation-iteration-count: $iteration;
    @keyframes #{$name} {
        0% {width: 0%}
        100% {width: 100%}
    }

    @-webkit-keyframes  #{$name} {
        0% {width: 0%}
        100% {width: 100%}
    }
}

@mixin animation-height-mixin($name, $interval, $iteration ) {
    animation-name: $name;
    animation-duration: $interval;
    animation-iteration-count: $iteration;
    -webkit-animation-name: $name;
    -webkit-animation-duration: $interval;
    -webkit-animation-iteration-count: $iteration;
    @keyframes #{$name} {
        0% {height: 0%}
        100% {height: 100%}
    }

    @-webkit-keyframes  #{$name} {
        0% {height: 0%}
        100% {height: 100%}
    }
}

@mixin animation-opacity-mixin($name, $interval, $iteration ) {
    animation-name: $name;
    animation-duration: $interval;
    animation-iteration-count: $iteration;
    -webkit-animation-name: $name;
    -webkit-animation-duration: $interval;
    -webkit-animation-iteration-count: $iteration;
    @keyframes #{$name} {
        0% {opacity: 0%}
        100% {opacity: 100%}
    }

    @-webkit-keyframes  #{$name} {
        0% {opacity: 0%}
        100% {opacity: 100%}
    }
}
