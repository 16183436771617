.switch-off-top-nav {
    @media (min-width: $xl) {
        display: none;
    }
}

#home {
    top: 80px;
    @media (min-width: $xl) {
        top: 0;
    }

    #map {
        height: 350px;
    }

    #szim-dynamic-banner {
        background-color: $szimblue;
    }

    #registration-link {
        background-image: url("../../../images/register_CTA.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto;
        padding: 60px 0;
        position: relative;
        color: white;
        overflow: hidden;
    }

    #registration-link:after {
        content: " ";
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(46, 96, 167, 0.76);
    }

    #said-about-us {
        padding-bottom: 45px;
        padding-top: 45px;

        .said-about-us-title {
            color: $szimgrey;
        }

        @media screen and (max-width: 992px) {
            .said-about-us-card-container {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        .said-about-us-card {
            padding: 30px;
            border-radius: 5px;
            p {
                font-size: 18px;
                line-height: 22px;
                color: $szimgrey;
                font-weight: 300;
            }
            .name {
                font-size: 18px;
                line-height: 22px;
                color: $szim-midblue;
                font-weight: 600;
            }
        }
    }

    #sub-page-link-cards {
        padding-bottom: 85px;
        padding-top: 85px;

        .sub-page-link-card-container {
            background-size: cover;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
        }

        @media screen and (max-width: 992px) {
            .sub-page-link-card-container {
                position: relative;
                border-radius: 15px;
                overflow: hidden;
                background-position: center;

                @media (max-width: $lg) {
                    height: 200px;
                }
            }
        }

        .sub-page-link-card {
            padding: 120px 30px 30px 30px;
            background-color: rgba(55, 171, 248, 0.7);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            @media (max-width: $lg) {
                padding: 30px 30px 30px 30px;
            }

            .sub-page-link-card-text {
                @media (max-width: $lg) {
                    font-size: 24px;
                    line-height: 26px;
                }

                .sub-page-link-card-br {
                    @media (max-width: $lg) {
                        display: none;
                    }
                }
            }
        }

        .egyeni {
            background-image: url("../../../images/egyeni_lofasz.png");
        }

        .csoportos {
            background-image: url("../../../images/csoportos.png");
        }

        .reumatologiai {
            background-image: url("../../../images/reuma.png");
            @media (max-width: 475px) {
                height: 226px !important;
            }
        }

        i {
            font-size: 36px;
        }
    }

    #registration-link {
        > * {
            z-index: 2;
        }
        &:after {
            content: " ";
            z-index: 1;
            display: block;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(46, 96, 167, 0.76);
        }
    }

    #szim-carousel {
        .szim-carousel {
            padding-top: 60px;
        }
    }

    #contact {
        .contact-info {
            top: -60px;
            color: white;
            background-color: $szimblue;
            padding: 60px;
            font-size: 14px;
            border-radius: 5px;
            @media (max-width: $lg) {
                top: 0;
                padding: 60px 15px;
            }

            .contact-info-title {
                font-weight: 700;
                margin-bottom: 60px;
            }

            .contact-text-col {
                padding-left: 20px;
                margin-bottom: 40px;
                @include center-v(80px);

                & a {
                    color: white !important;
                }

                .contact-info-subtitle {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: bold;
                    padding-bottom: 10px;
                }
            }

            .contact-text-col-last {
                margin-bottom: 0;
            }

            .contact-icon-col {
                padding: 0 0 0 15px;
                max-width: 95px;

                .contact-rounded-icon-border {
                    border: $sbtn-border solid white;
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    @include center-h(80px);

                    .contact-icon {
                        font-size: 28px;
                    }
                }
            }
        }

        .contact-form {
            .form-legend {
                padding: 30px 0px 30px 15px;
                color: $szimgrey;
            }

            .form-title {
                font-size: 22px;
                font-weight: bold;
            }

            input,
            textarea {
                font-size: 14px;
                line-height: 1.2;
                padding: 10px 15px;
                min-height: 40px;
            }
        }
    }
}

.contact-form, form {
    .form-legend {
        padding: 30px 0px 30px 15px;
        color: $szimgrey;
    }

    .form-title {
        font-size: 22px;
        font-weight: bold;
    }
    input,
    textarea {
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 15px;
        min-height: 40px;
    }
    input[type="checkbox"] {
        display: inline-block;
        min-height: initial;
        margin-right: 6px;
    }
}

form {
    .form-control{
        font-size: 14px;
        color: black;
    }
}
