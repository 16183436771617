.sbtn {
    &-primary,
    &-secondary,
    &-ghost,
    &-tertiary,
    &-danger,
    &-warning,
    button,
    .button {
        position: relative;
        transition: $smooth;
        border-radius: $sbtn-rad;
        border: $sbtn-border solid $szim-midblue;
        color: $white;
        padding: 15px;
        display: inline-block;
        padding: 15px 35px;
        font-weight: 400;
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        span {
            text-align: left;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
        &:hover {
            text-decoration: none;
            box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
            color: $white;
            cursor: pointer;
        }
        &.sm {
            padding: 0.375rem 1rem;
        }
    }
    &-primary {
        background-color: $szim-midblue;
        border: none;
        color: $white;
        &:hover {
            background-color: $szim-midblue;
            text-decoration: none;
            border: none;
        }
    }
    &-secondary {
        background-color: $white;
        border-radius: $sbtn-rad;
        border: $sbtn-border $white solid;
        color: $szimblue;
        &:hover {
            color: $white;
            background-color: $szimblue;
            border: $sbtn-border $szimblue solid;
        }
    }
    &-danger {
        background-color: $red;
        border: none;
        color: $white;
        font-weight: bold;
        &:hover {
            background-color: $red;
            text-decoration: none;
            border: none;
        }
    }
    &-warning {
        background-color: $orange;
        border: none;
        color: $white;
        font-weight: bold;
        &:hover {
            background-color: $orange;
            text-decoration: none;
            border: none;
        }
    }
    &-tertiary {
        background-color: $white;
        border-radius: $sbtn-rad;
        border: $sbtn-border $white solid;
        color: $szimblue;
        &:hover {
            color: $white;
            background-color: $szim-midblue;
            border: $sbtn-border $szim-midblue solid;
        }
    }
    &-ghost {
        background-color: transparent;
        border-radius: $sbtn-rad;
        border: $sbtn-border white solid;
        color: white;
        display: inline-block;
        &:hover {
            background-color: $white;
            text-decoration: none;
            color: $szim-midblue;
        }
    }
}

.sbtn-width-190 {
    width: 190px;
}

.animated-arrow {
    position: absolute;
    font-size: 25px;
    line-height: 16px;
}
