nav.top {
    background: white;
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 5rem;
    .row {
        justify-items: flex-start;
        .navbar-item {
            padding: 18px 30px 17px 0px;
            display: inline-block;
            a {
                color: $szimgrey;
                font-family: "Open sans", sans-serif, serif;
                font-size: 1.2rem;
                line-height: 1;
                font-weight: 600;
                &:hover {
                    text-decoration: none;
                    color: $szimblue;
                }
            }
        }
    }

    i {
        font-size: 15px;
        margin-right: 7px;
    }
}

#hamburger-menu-outer nav.top {
    @media (max-width: $xl) {
        display: none;
    }
}