@import "../szimmetria/resources/sass/variables";

.js-cookie-consent.cookie-consent {
    border-top: 2px solid white;
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 20px;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 102;
    font-family: "Open sans", sans-serif,arial;
    font-weight: 700;
}
span.cookie-consent__message {
    color: white;
}
button.js-cookie-consent-agree {
    border: $sbtn-border solid white;
    border-radius: 3px;
    background: #2E60A7;
    padding: 10px 15px;
    margin: 10px;
    font-size: 16px;
    color: #fff;
}
